<template>
  <div>
    <vue-dropzone
      v-if="vdropzoneOptions"
      ref="myVueDropzone"
      id="dropzone"
      @vdropzone-success="vsuccess"
      :options="vdropzoneOptions"
    ></vue-dropzone>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
const { getUrl, getUrlForLocalUpload, getUrlForResume } = require("../../assets/js/service");
export default {
  props: ["imageData"],
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      vdropzoneOptions: null,
    };
  },

  mounted() {
    if (this.imageData.imageNo == 0) {
      this.$store.commit("set", ["images", []]);
    }
    if (this.imageData.imageNo == 1) {
      this.$store.commit("set", ["images1", []]);
    }
    if (this.imageData.imageNo == 2) {
      this.$store.commit("set", ["images2", []]);
    }
    if (this.imageData.imageNo == 3) {
      this.$store.commit("set", ["images3", []]);
    }
    if (this.imageData.imageNo == 4) {
      this.$store.commit("set", ["images4", []]);
    }
    if (this.imageData.imageNo == 5) {
      this.$store.commit("set", ["images5", []]);
    }
    if (this.imageData.imageNo == 6) {
      this.$store.commit("set", ["images6", []]);
    }
    if (this.imageData.imageNo == 7) {
      this.$store.commit("set", ["images7", []]);
    }
    if (this.imageData.imageNo == 8) {
      this.$store.commit("set", ["images8", []]);
    }
    if (this.imageData.imageNo == 9) {
      this.$store.commit("set", ["images9", []]);
    }
    if (this.imageData.imageNo == 10) {
      this.$store.commit("set", ["images10", []]);
    }
    if (this.imageData.imageNo == 11) {
      this.$store.commit("set", ["images11", []]);
    }
    if (this.imageData.imageNo == 12) {
      this.$store.commit("set", ["images12", []]);
    }
    if (this.imageData.imageNo == 13) {
      this.$store.commit("set", ["images13", []]);
    }
    if (this.imageData.imageNo == 14) {
      this.$store.commit("set", ["images14", []]);
    }
    if (this.imageData.imageNo == 15) {
      this.$store.commit("set", ["images15", []]);
    }
    if (this.imageData.imageNo == "filePDF") {
      this.$store.commit("set", ["filePDF", []]);
    }
    if (this.imageData.imageNo == "Resume") {
      this.$store.commit("set", ["filePDF", []]);
    }


    if (this.imageData.imageNo == "filePDF") {
      this.vdropzoneOptions = {
        url: getUrlForLocalUpload(true),
        thumbnailWidth: 150,
        maxFilesize: 100,
        addRemoveLinks: true,
        acceptedFiles: ".pdf",
        maxFiles: this.imageData.maxNoImg,
        dictDefaultMessage:
          "<i class='fa fa-cloud-upload'></i> UPLOAD Your PDF",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      };
      this.vdropzoneOptions.maxFiles = this.imageData.maxNoImg;
    } else if(this.imageData.imageNo == "Resume"){
       this.vdropzoneOptions = {
        url: getUrlForResume(true),
        thumbnailWidth: 150,
        maxFilesize: 50,
        addRemoveLinks: true,
        acceptedFiles: ".pdf",
        maxFiles: this.imageData.maxNoImg,
        dictDefaultMessage:
          "<i class='fa fa-cloud-upload'></i> UPLOAD Your PDF",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      };
      this.vdropzoneOptions.maxFiles = this.imageData.maxNoImg;
    }
    
    else {
      this.vdropzoneOptions = {
        url: getUrl(),
        thumbnailWidth: 150,
        maxFilesize: 50,
        
        addRemoveLinks: true,
        maxFiles: this.imageData.maxNoImg,
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD PICTURE",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      };
      this.vdropzoneOptions.maxFiles = this.imageData.maxNoImg;
    }
  },
  methods: {
    vsuccess(file, response) {

      if (this.imageData.imageNo == 0) {
        let images = this.$store.state.images;
        images.push(response.secure_url);
      } else if (this.imageData.imageNo == 1) {
        let images = this.$store.state.images1;
        images.push(response.secure_url);
      } else if (this.imageData.imageNo == 2) {
        let images = this.$store.state.images2;
        images.push(response.secure_url);
      } else if (this.imageData.imageNo == 3) {
        let images = this.$store.state.images3;
        images.push(response.secure_url);
      } else if (this.imageData.imageNo == 4) {

        let images = this.$store.state.images4;
        images.push(response.secure_url);
      } else if (this.imageData.imageNo == 5) {
        let images = this.$store.state.images5;
        images.push(response.secure_url);
      } else if (this.imageData.imageNo == "filePDF") {
        let filePDF = this.$store.state.filePDF;

        filePDF.push(response.result.files.file[0].name);
        this.$store.commit("set",['filePDFSize',file.size])
        this.$store.commit("set",['filePDF',getUrlForLocalUpload(false)+'/'+response.result.files.file[0].name])
      } else if (this.imageData.imageNo == "Resume") {
        let filePDF = this.$store.state.filePDF;

        filePDF.push(response.result.files.file[0].name);
        this.$store.commit("set",['filePDFSize',file.size])
        this.$store.commit("set",['filePDF',getUrlForResume(false)+'/'+response.result.files.file[0].name])
      }
    },
  },
};
</script>
